var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-full py-5 px-5"},[_c('div',{staticClass:"flex justify-center items-center flex-row w-full"},[_c('div',{staticClass:"flex justify-between items-center flex-wrap flex-row w-full"},[_c('div',{staticClass:"flex w-full flex-wrap items-center justify-between",staticStyle:{"max-width":"280px"}},[_c('span',{staticClass:"text-xs"},[_vm._v("Nama Produk")]),_c('b-input',{staticStyle:{"max-width":"180px","width":"100%","font-size":"12px"},attrs:{"lazy":true,"size":"is-small"},on:{"input":_vm.searchByNameSaving},model:{value:(_vm.property.filterDto.name),callback:function ($$v) {_vm.$set(_vm.property.filterDto, "name", $$v)},expression:"property.filterDto.name"}})],1),_c('b-button',{staticClass:"bprs-hik-theme-background button-theme-confirm",attrs:{"label":"Produk Tabungan","icon-left":"plus","size":"is-small","disabled":!_vm.$store.getters['getActionsInsert']},on:{"click":_vm.routeToPageAddSaving}})],1)]),_c('b-table',{staticClass:"my-4",attrs:{"mobile-cards":false,"data":_vm.table.data.saving,"striped":"","narrowed":"","bordered":""},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('div',{staticClass:"flex justify-center items-center py-2"},[(_vm.property.listElement.saving.downloading)?_c('cbs-table-progres',{attrs:{"size":"10px"}}):_vm._e(),(_vm.property.listElement.saving.downloading === false)?_c('span',{staticClass:"text-base"},[_vm._v(" "+_vm._s(_vm.property.listElement.saving.message)+" ")]):_vm._e()],1)]},proxy:true}])},[_c('b-table-column',{attrs:{"header-class":"bprs-hik-theme-background","centered":"","field":"mproductCategory.productCategoryName","label":"Jenis Produk"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('span',{staticClass:"text-white text-xs"},[_vm._v(_vm._s(column.label))])]}},{key:"default",fn:function(props){return [_c('span',{staticClass:"text-xs"},[_vm._v(_vm._s(props.row.mproductCategory.productCategoryName))])]}}])}),_c('b-table-column',{attrs:{"header-class":"bprs-hik-theme-background","centered":"","field":"savingCode","label":"Kode Produk"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('span',{staticClass:"text-white text-xs"},[_vm._v(_vm._s(column.label))])]}},{key:"default",fn:function(props){return [_c('span',{staticClass:"text-xs"},[_vm._v(_vm._s(props.row.savingCode))])]}}])}),_c('b-table-column',{attrs:{"header-class":"bprs-hik-theme-background","centered":"","field":"savingName","label":"Nama Produk"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('span',{staticClass:"text-white text-xs"},[_vm._v(_vm._s(column.label))])]}},{key:"default",fn:function(props){return [_c('span',{staticClass:"text-xs"},[_vm._v(_vm._s(props.row.savingName))])]}}])}),_c('b-table-column',{attrs:{"header-class":"bprs-hik-theme-background","centered":"","field":"profitSharePercent","label":"Besaran Bagi Hasil (%)"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('span',{staticClass:"text-white text-xs"},[_vm._v(_vm._s(column.label))])]}},{key:"default",fn:function(props){return [_c('span',{staticClass:"text-xs"},[_vm._v(_vm._s(props.row.profitSharePercent ? props.row.profitSharePercent : 0))])]}}])}),_c('b-table-column',{attrs:{"header-class":"bprs-hik-theme-background","centered":"","field":"balanceMinimum","label":"Minimum Saldo"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('span',{staticClass:"text-white text-xs"},[_vm._v(_vm._s(column.label))])]}},{key:"default",fn:function(props){return [_c('span',{staticClass:"text-xs"},[_vm._v(_vm._s(props.row.balanceMinimum ? _vm.convertDecimalCurrency(props.row.balanceMinimum) : props.row.balanceMinimum))])]}}])}),_c('b-table-column',{attrs:{"header-class":"bprs-hik-theme-background","centered":"","field":"initialDepositMinimum","label":"Setoran Awal"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('span',{staticClass:"text-white text-xs"},[_vm._v(_vm._s(column.label))])]}},{key:"default",fn:function(props){return [_c('span',{staticClass:"text-xs"},[_vm._v(_vm._s(props.row.initialDepositMinimum ? _vm.convertDecimalCurrency(props.row.initialDepositMinimum) : props.row.initialDepositMinimum))])]}}])}),_c('b-table-column',{attrs:{"header-class":"bprs-hik-theme-background","centered":"","field":"rstatus.statusName","label":"Status"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('span',{staticClass:"text-white text-xs"},[_vm._v(_vm._s(column.label))])]}},{key:"default",fn:function(props){return [_c('span',{staticClass:"text-xs"},[_vm._v(_vm._s(props.row.rstatus.statusName))])]}}])}),_c('b-table-column',{attrs:{"header-class":"bprs-hik-theme-background","centered":"","cell-class":"py-2","field":"","label":"Aksi"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('span',{staticClass:"text-white text-xs"},[_vm._v(_vm._s(column.label))])]}},{key:"default",fn:function(props){return [_c('b-dropdown',{attrs:{"can-close":"outside","append-to-body":"","aria-role":"menu"},scopedSlots:_vm._u([{key:"trigger",fn:function(ref){
var active = ref.active;
return [_c('b-button',{staticClass:"font-bold border-none outline-none text-white hover:text-white focus:text-white ",staticStyle:{"border-radius":"3px","font-size":"10px"},attrs:{"size":"is-small","label":"Aksi","type":"is-success","icon-right":active ? 'menu-up' : 'menu-down'}})]}}],null,true)},[_c('b-dropdown-item',{staticClass:"flex justify-left text-xs",attrs:{"disabled":!_vm.$store.getters['getActionsUpdate'],"aria-role":"menuitem"},on:{"click":function($event){return _vm.routeToPageEditSaving(props)}}},[_vm._v(" Edit ")]),_c('b-dropdown-item',{staticClass:"flex justify-left text-xs",attrs:{"disabled":!_vm.$store.getters['getActionsDetail'],"aria-role":"menuitem"},on:{"click":function($event){return _vm.routeToPageDetailSaving(props)}}},[_vm._v("Detail")]),_c('b-dropdown-item',{staticClass:"flex justify-left text-xs",attrs:{"disabled":!_vm.$store.getters['getActionsDelete'],"aria-role":"menuitem"},on:{"click":function($event){return _vm.deleteSaving(props)}}},[_vm._v("Delete")]),_c('b-dropdown-item',{staticClass:"flex justify-left text-xs",attrs:{"aria-role":"menuitem","disabled":!_vm.$store.getters['getActionsAuthorize'] ||
                props.row.rstatus.statusId === '3' ||
                props.row.rstatus.statusId === '2'},on:{"click":function($event){return _vm.authorizationSaving(props)}}},[_vm._v("Authorisasi")])],1)]}}])})],1),_c('b-pagination',{attrs:{"total":_vm.property.listElement.saving.rows,"range-before":1,"range-after":1,"order":"is-left","size":"is-small","per-page":_vm.property.listElement.saving.perPage,"icon-prev":"chevron-left","icon-next":"chevron-right"},on:{"change":_vm.changePaginationSaving},model:{value:(_vm.property.listElement.saving.currentPage),callback:function ($$v) {_vm.$set(_vm.property.listElement.saving, "currentPage", $$v)},expression:"property.listElement.saving.currentPage"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }